import {useState, useEffect} from 'react';

export const baseURL = "https://pay.displaycentre.co.uk/api/";

export const POST = (url, params, onDone) => {
    let request = new XMLHttpRequest();
    request.onreadystatechange = function () {
        if (this.readyState === 4) {
            try {
                let json = JSON.parse(this.responseText);
                onDone(json);
            }
            catch (e) {
                console.log(e);
                onDone({
                    success: false
                });
            }
        }
    };

    request.open("POST", baseURL + url, true);

    //build param string
    let data = new FormData();
    for (let key in params) {
        data.append(key, params[key]);
    }

    request.send(data);
};

export const useApi = (url, params, deps) => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        setLoading(true);
        POST(url, params, result => {
            setLoading(false);
            if (result.success) {
                setData(result.result);
                setError(null);
            }
            else {
                setData(null);
                setError(result.error);
            }
        });
    }, deps);

    return [data, loading, error];
};