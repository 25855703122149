import React from 'react';
import moment from "moment";
import * as PaymentStatus from "../PaymentStatus";
import {BarLoader} from "react-spinners";

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        background: '#d8e8e4',
        minHeight: '100vh',
        alignItems: 'flex-start'
    },
    card: {
        width: '20cm',
        maxWidth: '90%',
        margin: 32,
        padding: 32,
        background: 'white',
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        borderRadius: 16
    },
    headerRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    subheaderRow: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginTop: 48
    },
    infoRow: {
        marginTop: 48
    },
    addressLine: {
        margin: 0
    },
    subHeading: {
        marginTop: 32,
        fontWeight: 800,
        color: '#4f0058'
    },
    logo: {
        width: 300
    }

};

const Money = ({children}) => {
    const pence = parseFloat(children) * 100;
    const fullPence = Math.round(pence % 100);
    const pounds = Math.round((pence - fullPence) / 100);
    let penceString = "" + fullPence;
    if (penceString.length < 2) {
        penceString = "0" + penceString;
    }
    return pounds + "." + penceString;
};

const Header = () => (
    <div style={styles.headerRow}>
        <img alt="Logo" style={styles.logo} src={require("../assets/logo.png")}/>
    </div>
);

const Info = () => (
    <div style={styles.infoRow}>
        <p>The Display Centre is inviting you to make a payment in connection with the reference shown below. This form is intended to make the payment process easy for
            you whilst, at the same time, ensuring the security of all personal and payment details.</p>
    </div>
);

const PaymentInfo = ({OrderNumber, CURRENCYCODE, OrderDate, OrderTotal}) => {
    const items = [
        {label: "Reference", value: "Order " + OrderNumber},
        {label: "Date", value: moment(OrderDate.date, "YYYY-MM-DD").format("DD/MM/YYYY")},
        {label: "Currency", value: CURRENCYCODE || "GBP"},
        {label: "Amount", value: <Money>{OrderTotal}</Money>},
    ];
    return (
        <div>
            <h3 style={styles.subHeading}>Pay Now</h3>

            <table className="w3-table w3-border w3-bordered">
                <tbody>
                {items.map(({label, value}) => (
                    <tr key={label + value}>
                        <td>{label}</td>
                        <td>{value}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

const LoadingView = () => (
    <div>
        <p>Please wait while we process your payment. Do not close this page.</p>
        <BarLoader color='#6F338B' width="100%"/>
    </div>
);

const PaidView = ({orderID}) => (
    <div>
        <h3 style={styles.subHeading}>Thank you</h3>
        <p>Your payment has been processed. You may now close this page.</p>
    </div>
);

const PaymentFailedView = ({errorMessage, orderID}) => (
    <div>
        <h3 style={styles.subHeading}>Payment Failed</h3>
        <p>{errorMessage}</p>
        <p>Order ID: {orderID}</p>
        <button className="w3-button" onClick={() => window.location.reload()}>Retry</button>
    </div>
);

export const OrderScreen = ({order, paymentPortal, paymentStatus, errorMessage, orderID}) => {
    let paymentView = null;
    if (paymentStatus === PaymentStatus.PROCESSING || paymentStatus === PaymentStatus.VERIFYING) {
        paymentView = <LoadingView/>
    }
    if (paymentStatus === PaymentStatus.PAID) {
        paymentView = <PaidView orderID={orderID}/>
    }
    if (paymentStatus === PaymentStatus.FAILED) {
        paymentView = <PaymentFailedView errorMessage={errorMessage} orderID={orderID}/>
    }
    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <Header />
                <Info/>
                <PaymentInfo {...order}/>
                <div style={{marginTop: 48}}>
                    <div style={{display: paymentStatus === PaymentStatus.WAITING ? "block" : "none"}}>
                        {paymentPortal}
                    </div>
                    {paymentView}
                </div>
            </div>
        </div>
    );
};
