import * as KJUR from "jsrsasign";

export const getQueryParameter = key => {
    //gets the url param value for a key
    const search = window.location.search.substr(1);

    const parts = search.split("&");
    const target = parts.filter(e => e.indexOf(key + "=") === 0);
    if (target.length > 0) {
        return target[0].substring((key + "=").length);
    }
    return null;
};

export const parseJWT = jwt => {
    try {
        return KJUR.jws.JWS.readSafeJSONString(KJUR.b64utoutf8(jwt.split(".")[1]));
    }
    catch (e) {
        return {};
    }
};