import React, {useState, useMemo, useEffect} from 'react';
import './App.css';
import './w3.css';
import * as util from "./util";
import * as api from "./api";
import {OrderScreen} from "./screens/OrderScreen";
import PaymentPortal from "./components/PaymentPortal";
import * as PaymentStatus from "./PaymentStatus";

function App() {
    const token = util.getQueryParameter("token");
    const [data, loading, error] = api.useApi("infoForToken.php", {token}, [token]);

    const [paymentStatus, setPaymentStatus] = useState(PaymentStatus.WAITING);
    const [errorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState("");

    const onPay = (details) => {
        console.log(details);
        setPaymentStatus(PaymentStatus.VERIFYING);
        const orderID = details.orderID;
        setOrderID(orderID);
        api.POST("verifyPayment.php", {orderID, token}, result => {
            if (result.success) {
                setPaymentStatus(PaymentStatus.PAID);
            }
            else {
                setPaymentStatus(PaymentStatus.FAILED);
                if (result.error == null) {
                    setErrorMessage("An unknown error occurred. You may have been charged. Please contact support, quoting the order ID below");
                }
                else
                    setErrorMessage(result.error);
            }
        });
    };

    useEffect(() => {
        if (data && data.hasPaid) {
            setPaymentStatus(PaymentStatus.PAID);
        }
    }, [data]);

    const startPay = () => setPaymentStatus(PaymentStatus.PROCESSING);
    const cancelPay = () => setPaymentStatus(PaymentStatus.WAITING);

    const orderTotal = data === null ? null : data.order.OrderTotal;
    const paymentPortal = useMemo(() => <PaymentPortal orderTotal={orderTotal} onPay={onPay} startPay={startPay} cancelPay={cancelPay}/>, [orderTotal]);

    if (loading) {
        return <div/>;
    }
    if (error !== null) {
        return <div>{error}</div>
    }

    return <OrderScreen paymentStatus={paymentStatus} order={data.order} paymentPortal={paymentPortal} errorMessage={errorMessage} orderID={orderID}/>
}

export default App;
