import React, {useRef, useEffect} from 'react';

const styles = {
    paymentPortal: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    greyText: {
        color: '#555'
    },
};

export const PaymentPortal = ({onPay, startPay, cancelPay, orderTotal}) => {

    const paypalRef = useRef();

    useEffect(() => {
        if (orderTotal == null) {
            console.log("not rendering payment yet");
            return;
        }
        console.log("rendering payment now with " + orderTotal);
        window.paypal.Buttons({
            createOrder: function (data, actions) {
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            value: orderTotal
                        }
                    }],
                    application_context: {
                        shipping_preference: "NO_SHIPPING"
                    }
                });
            },
            onApprove: function (data, actions) {
                startPay();
                onPay(data);
            },
        }).render(paypalRef.current);
    }, [orderTotal]);

    return (
        <div style={styles.paymentPortal}>
            <div ref={paypalRef}/>
            <p style={styles.greyText}>Note: PayPal uses the latest technologies to streamline transactions while implementing methods to help secure card data. The Display
                Centre’s use of PayPal means that your sensitive transaction data never touches the company's servers as it goes direct from this web page to PayPal.</p>
        </div>
    );
};

export default PaymentPortal;